import { API_ORIGIN, HTTP_METHODS } from "../shared/common/constant";
import HttpService from "./http.service";

class TrialService {

  static getTrials = async () => {
    try {
      const endpoint = API_ORIGIN + 'trials';

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET,
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error('Failed to get trials: ' + error);
    }
  }
}

export default TrialService;