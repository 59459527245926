import { ActionTypes, FORM } from "../shared/common/constant";

export const ModifyConfig = {
  ModifyFile: {
    id: "modifyBrandingImage",
    title: "Update branding image",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT_FILE,
        label: "Branding image (Max image size 1MB)",
        name: "brandingImagePlaceholder",
        type: FORM.TYPES.FILE,
        required: false,
        defaultValue: "",
        placeholder: "",
        accept: "image/*",
        onActionType: ActionTypes.selectImage,
        className:
          "border border-transparent rounded focus:outline-none focus:border-transparent",
      },
      {
        element: FORM.ELEMENTS.INV,
        name: "brandingImage",
        type: "hidden",
        required: false,
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center mt-5 px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center mt-5 px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
  ModifyText: {
    id: "modifyText",
    title: "Update branding text",
    label: "Branding text",
    fields: [
      {
        element: FORM.ELEMENTS.TEXTAREA,
        label: "Branding text",
        name: "brandingText",
        type: FORM.TYPES.TEXT,
        required: false,
        defaultValue: "",
        placeholder: "Branding text",
        rows: "5",
        maxLength: "1000",
        className: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },

  ModifyName: {
    id: "modifyName",
    title: "Update company name",
    label: "Company name",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Company name",
        name: "companyName",
        type: FORM.TYPES.TEXT,
        required: false,
        defaultValue: "",
        placeholder: "Example Inc.",
        className: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
  ModifyContact: {
    id: "modifyContact",
    title: "Update primary contact",
    label: "Primary contact",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT,
        label: "Primary contact",
        name: "primaryContactEmail",
        type: FORM.TYPES.EMAIL,
        required: true,
        defaultValue: "",
        placeholder: "email@example.com",
        className: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
  ModifyDomain: {
    id: "modifyDomain",
    title: "Update domain(s)",
    label: "Email domain(s)",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT_CHIP,
        label: "Email domain(s)",
        name: "domains",
        type: FORM.TYPES.URL,
        required: false,
        defaultValue: "",
        placeholder: "example.com",
        className: "",
        disabled: "",
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Close",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
  ModifyAccessCode: {
    id: "modifyAccessCode",
    title: "Update access code",
    label: "Access code",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT_CODE,
        label: "Access code",
        name: "accessCode",
        type: FORM.TYPES.TEXT,
        required: true,
        defaultValue: "",
        placeholder: "exampleCode123",
        className: "",
        validation: {
          min: 1,
          max: 30,
        },
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
  ModifyUrlCompany: {
    id: "modifyUrlCompanyName",
    title: "Update URL Company name",
    label: "URL Company name",
    fields: [
      {
        element: FORM.ELEMENTS.INPUT,
        label: "URL Company name",
        name: "accessCode",
        type: FORM.TYPES.TEXT,
        required: false,
        defaultValue: "",
        placeholder: "example-inc",
        className: "",
      },
    ],
  },
};
