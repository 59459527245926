import { API_ORIGIN, HTTP_METHODS } from "../shared/common/constant";
import HttpService from "./http.service";

class EnterpriseService {

  static getEnterprises = async () => {
    try {
      const endpoint = API_ORIGIN + 'enterprises';

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET,
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error('Failed to get enterprises: ' + error);
    }
  }

  static getEnterpriseLicenses = async (id) => {
    try {
      const endpoint = API_ORIGIN + `enterprises/${id}/licenses`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error('Failed to get enterprise licenses: ' + error);
    }
  }

  static getEnterpriseDetails = async (id) => {
    try {
      const endpoint = API_ORIGIN + `enterprises/${id}`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to get enterprise details: " + error);
    }
  };

  static createEnterprises = async (body) => {
    try {
      const endpoint = API_ORIGIN + 'enterprises';

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error('Failed to create enterprise: ' + error);
    }
  }

  static createUniversalKeyUser = async (body) => {
    try {
      const endpoint = API_ORIGIN + "enterprises/universal-key-user";
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to create universal key user: " + error);
    }
  };

  static deactivateEnterprises = async (id) => {
    try {
      const endpoint = API_ORIGIN + `enterprises/${id}:deactivate`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error('Failed to deactivate enterprise: ' + error);
    }
  }

  static activateEnterprises = async (id) => {
    try {
      const endpoint = API_ORIGIN + `enterprises/${id}:activate`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error('Failed to activate enterprise: ' + error);
    }
  }

  static deleteEnterpriseLicense = async (id, licenseId) => {
    try {
      const endpoint = API_ORIGIN + `enterprises/${id}/licenses/${licenseId}`;

      await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.DELETE
      );

      return;
    } catch (error) {
      throw Error('Failed to delete enterprise license: ' + error);
    }
  }

  static modifyEnterprise = async (body) => {
    try {
      const endpoint = API_ORIGIN + `enterprises`;
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify enterprise details: " + error);
    }
  };

  static modifyEnterpriseExpiry = async (id, body) => {
    try {
      const endpoint = API_ORIGIN + `enterprises/${id}/licenses`;
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.PATCH,
        body
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to modify enterprise expiration date details: " + error);
    }
  };
}

export default EnterpriseService;