import { LOGIN, LOGOUT, SET_TOKEN } from "./auth.types";


export const setAuthOnSignIn = ({ user }) => {
	return {
		type: LOGIN,
		payload: user
	};

};

export const setAuthOnLogout = () => {
	return {
		type: LOGOUT,
	};
};

export const setAuthToken = ({ token }) => {
	return {
		type: SET_TOKEN,
		payload: token
	};
};