import { combineReducers } from 'redux';

import appReducer from './app/app-reducer';
import authReducer from './auth/auth-reducer';
import licenseReducer from './license/license-reducer';


export const reducers = combineReducers({
    app: appReducer,
    auth: authReducer,
    license: licenseReducer
});
