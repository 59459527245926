import { ADD_ALERT, HIDE_BTN_LOADING, HIDE_LOADING, REMOVE_ALERT, SHOW_BTN_LOADING, SHOW_LOADING } from "./app.types";

const INITIAL_STATE = {
  alerts: [],
  isLoading: false,
  isBtnLoading: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_ALERT:
        let stateAlerts = [...state.alerts];
        if(stateAlerts.length > 0) {
          stateAlerts = [];
        }
        stateAlerts.push({ type: action.payload.type, text: action.payload.text });
      return {
        ...state,
        alerts: [...stateAlerts],
      };

    case REMOVE_ALERT:
        const index = action.payload.index;
        const alerts = [...state.alerts];
        if(alerts[index]) {
            alerts.splice(index, 1);
        }
      return {
        ...state,
        alerts: [...alerts]
      };

    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false
      };
    
    case SHOW_BTN_LOADING:
      return {
        ...state,
        isBtnLoading: true
      };

    case HIDE_BTN_LOADING:
      return {
        ...state,
        isBtnLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
