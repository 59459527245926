import { STORAGE } from "../../shared/common/constant";
import { LOGIN, LOGOUT, SET_TOKEN } from "./auth.types";

const getUserState = () => {
  const authStorage = window.localStorage.getItem(STORAGE.VODIUM_AUTH);
  if (authStorage) {
    const auth = JSON.parse(authStorage);
    return auth;
  }
  return null;
};

const getAuthState = () => {
  return getUserState() === null ? false : true;
};

const INITIAL_STATE = {
  isAuthenticated: getAuthState(),
  user: getUserState(),
  token: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      }

    default:
      return state;
  }
};

export default reducer;
