import store from "../redux/store";
import { HTTP_METHODS } from "../shared/common/constant";


class HttpService {

  static setHeaders = (token) => {
    try {
      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${token}`);
      return headers;
    } catch (error) {
      return new Headers();
    }
  };

  static sendRequest = async (
    url,
    method,
    body = null
  ) => {
    const {auth} = await store.getState();

    if (auth.token === null || auth.token.length === 0) {
      throw Error('Aborting message request. Token at storage was empty.');
    }

    const headers = this.setHeaders(auth.token);
    let options = {
      method,
      headers,
      body
    };

    if (
      body &&
      (method === HTTP_METHODS.PATCH || method === HTTP_METHODS.POST)
    ) {
      if (typeof body === 'string') {
        options.body = body;
      } else {
        options.body = JSON.stringify(body);
      }
    }

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        const { error } = await response.json();
        throw Error(error);
      }
      return response;
    } catch (error) {
      throw Error(error);
    }
  };
}

export default HttpService;