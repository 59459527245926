import { API_ORIGIN, HTTP_METHODS } from "../shared/common/constant";
import HttpService from "./http.service";

class FirebaseService {

  static setCustomClaims = async (body) => {
    try {
      const endpoint = API_ORIGIN + "firebase/claim/update";

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.POST,
        body
      );

      const status = await response;

      return status;
    } catch (error) {
      throw Error("Failed to set custom claims: " + error);
    }
  };
}

export default FirebaseService;