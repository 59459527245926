import React from "react";
import Table, {
  InlineMenu,
  SelectColumnFilter,
  StatusIndicator,
} from "../components/Table";
import { TrialsConfig } from "../configs/trials";
import {
  ACTION_HANDLER_TYPE,
  ActionTypes,
  StatusKeys,
} from "../shared/common/constant";
import { Modal } from "../shared/Modal";
import Form from "../shared/Form";
import { showAlert } from "../shared/Alert";
import ActionHandler from "../services/data-handler";
import { DateFormat } from "../shared/Utils";
import {
  toggleBtnLoadingOff,
  toggleBtnLoadingOn,
} from "../services/app.service";
import { useNavigate } from "react-router-dom";

const Trials = () => {
  const [isGenarateModalOpen, setIsGenarateModalOpen] = React.useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = React.useState(false);
  const [updateData, setupdateData] = React.useState();
  const [data, setData] = React.useState([]);
  const [trialKeys, setTrialKeys] = React.useState([]);
  const dataRef = React.useRef([]);
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: (row) => DateFormat(row.createdAt, true),
      },
      {
        Header: "Description",
        accessor: (row) => row.firstName,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Expiration date",
        accessor: (row) =>
          new Date(row.expirationDateTime).toLocaleDateString("en-CA"),
      },
      {
        Header: "Status",
        accessor: (row) => row.status.toLowerCase(),
        Filter: SelectColumnFilter,
        filter: "exact",
        Cell: StatusIndicator,
      },
      {
        id: "action-column",
        Cell: (props) => {
          const menuOptions = [...TrialsConfig.tableMenuOptions];
          if (props.row.original.status === StatusKeys.ACTIVATED) {
            menuOptions.push({
              id: 1,
              value: "Archive key",
            });
            menuOptions.push({
              id: 2,
              value: "Deactivate key",
            });
            menuOptions.push({
              id: 3,
              value: "Update expiry date",
            });
          } else if (props.row.original.status === StatusKeys.DEACTIVATED) {
            menuOptions.push({
              id: 1,
              value: "Archive key",
            });
            menuOptions.push({
              id: 3,
              value: "Update expiry date",
            });
          } else if (props.row.original.status === StatusKeys.ARCHIVED) {
            menuOptions.push({
              id: 2,
              value: "Unarchive key",
            });
            menuOptions.push({
              id: 3,
              value: "Update expiry date",
            });
          }
          return InlineMenu({
            options: [...menuOptions],
            selectedItem: (option) => onMenuOptionSelect(option, props),
          });
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  React.useEffect(() => {
    getTrialLicenses();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    dataRef.current = [...data];
  }, [data]);

  const getTrialLicenses = async () => {
    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.GET_TRIAL_LICENSES
    );
    // Filter for trial licenses.
    const trialLicenses = response.licenses.filter(
      (license) =>
        license.subscriptionDetailsLink === null &&
        license.customerDetailsLink === null
    );
    trialLicenses.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    setData(trialLicenses);
  };

  const closeModal = () => {
    setIsGenarateModalOpen(false);
    if (trialKeys.length > 0) {
      setTrialKeys([]);
    }
  };

  const openModal = () => {
    setIsGenarateModalOpen(true);
  };

  const actionHandler = async ({ type, data, event }) => {
    switch (type) {
      case ActionTypes.modalClose:
        closeModal();
        if (isUpdateModalOpen) {
          setIsUpdateModalOpen(false);
        }
        break;
      case ActionTypes.formSubmit:
        toggleBtnLoadingOn();
        if (isUpdateModalOpen) {
          updateTrialExpiry(data.expirationDateTime);
          return;
        }
        if (data) {
          // convert string to ISO date-time
          data.expirationDateTime = new Date(
            data.expirationDateTime
          ).toISOString();
          const response = await ActionHandler(
            ACTION_HANDLER_TYPE.CREATE_LICENSE_BATCH,
            data
          );
          if (response) {
            getTrialLicenses();
            showAlert({
              type: ActionTypes.successAlert,
              text: "Trial(s) have been generated.",
            });
            setTrialKeys(response.keys);
            toggleBtnLoadingOff();
          }
        } else {
          showAlert({
            type: ActionTypes.failAlert,
            text: "Trial license generation failed, please try again.",
          });
          toggleBtnLoadingOff();
        }
        break;
      case ActionTypes.buttonClick:
        openModal();
        break;
      default:
        return;
    }
  };

  const copyKeysToClipboard = () => {
    let keyStr = "";
    trialKeys.forEach((key) => {
      keyStr += key + "\n";
    });
    navigator.clipboard.writeText(keyStr);
    showAlert({
      type: ActionTypes.successAlert,
      text: "Keys copied to clipboard.",
    });
  };

  const archiveTrial = async (rowData) => {
    rowData.id = String(rowData.id);
    rowData.status = StatusKeys.ARCHIVED;
    const response = await ActionHandler(ACTION_HANDLER_TYPE.MODIFY_LICENSE, {
      id: Number(rowData.id),
      body: rowData,
    });
    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Archived user license.",
        });
      }
    }
  };

  const deactivateTrial = async (rowData) => {
    rowData.id = String(rowData.id);
    rowData.status = StatusKeys.DEACTIVATED;
    const response = await ActionHandler(ACTION_HANDLER_TYPE.MODIFY_LICENSE, {
      id: Number(rowData.id),
      body: rowData,
    });
    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.id === response.id
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        updatedData[licenseIndex] = { ...response };
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "Deactivated user license.",
        });
      }
    }
  };

  const updateTrialExpiry = async (newExpiryDate) => {
    let rowData = updateData;
    rowData.id = String(rowData.id);
    // convert string to ISO date-time
    rowData.expirationDateTime = new Date(newExpiryDate).toISOString();

    const updateExpirationDate = {
      key: rowData.key,
      expirationDateTime: rowData.expirationDateTime,
    };

    const response = await ActionHandler(
      ACTION_HANDLER_TYPE.MODIFY_EXPIRY_DATE_TIME,
      {
        body: updateExpirationDate,
      }
    );

    if (response) {
      const licenseIndex = dataRef.current.findIndex(
        (license) => license.key === response.key
      );
      if (licenseIndex !== -1) {
        const updatedData = [...dataRef.current];
        setData([...updatedData]);
        showAlert({
          type: ActionTypes.successAlert,
          text: "License key expiration date updated.",
        });
      }
    }
    toggleBtnLoadingOff();
    setIsUpdateModalOpen(false);
  };

  const onMenuOptionSelect = (payload, props) => {
    const rowData = { ...props.row.original };
    switch (payload.id) {
      case 1:
        const archiveConfirmation = window.confirm(
          "Are you sure you wish to archive this license?"
        );
        if (archiveConfirmation === false) {
          return;
        }
        archiveTrial(rowData);
        break;
      case 2:
        const deleteConfirmation = window.confirm(
          "Are you sure you wish to deactivate this license?"
        );
        if (deleteConfirmation === false) {
          return;
        }
        deactivateTrial(rowData);
        break;
      case 3:
        setupdateData(rowData);
        setIsUpdateModalOpen(true);
        break;
      case 4:
        const licenseKey = rowData.key;
        navigate(`/trials/details/` + licenseKey);
        break;
      default:
        return;
    }
  };

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="py-11">
          <div className="px-6 mx-auto">
            <Table
              title="Custom Trials"
              columns={columns}
              data={data}
              button={TrialsConfig.GenerateForm.title}
              onAction={actionHandler}
            />
            <Modal isOpen={isGenarateModalOpen} onAction={actionHandler}>
              <div className="flex items-center justify-center w-full h-full">
                <div className="w-full max-w-sm">
                  {trialKeys.length === 0 && (
                    <div>
                      <p className="text-xl font-bold text-gray-900">
                        {TrialsConfig.GenerateForm.title}
                      </p>
                      <Form
                        className={"my-6"}
                        form={TrialsConfig.GenerateForm}
                        onAction={actionHandler}
                      />
                    </div>
                  )}
                  {trialKeys.length !== 0 && (
                    <React.Fragment>
                      <div className="flex items-center justify-between">
                        <p className="text-xl font-bold text-gray-900">
                          {TrialsConfig.GenerateForm.title}
                        </p>
                        <button
                          type="button"
                          onClick={closeModal}
                          className="inline-flex rounded -m1.5 p-1.5 text-gray-600 hover:bg-gray-50 hover:text-gray-900 transition-all duration-200"
                        >
                          <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div className="text-center my-5">
                        <div className="pt-3 text-sm font-medium text-gray-500">
                          {trialKeys.map((key, keyIndex) => {
                            return (
                              <p key={keyIndex} className="mb-2">
                                {key}
                              </p>
                            );
                          })}
                        </div>
                        <div className="mt-8">
                          <button
                            type="button"
                            onClick={copyKeysToClipboard}
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-indigo-600 transition-all duration-200 bg-indigo-100 border border-transparent rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-600"
                          >
                            Copy all keys
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Modal>
            <Modal isOpen={isUpdateModalOpen} onAction={actionHandler}>
              <div className="flex items-center justify-center w-full h-full">
                <div className="w-full max-w-sm">
                  {trialKeys.length === 0 && (
                    <div>
                      <p className="text-xl font-bold text-gray-900">
                        {TrialsConfig.UpdateForm.title}
                      </p>
                      <Form
                        className={"my-6"}
                        form={TrialsConfig.UpdateForm}
                        onAction={actionHandler}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Trials;
