import { SET_ENTERPRISE_LICENSES, SET_LICENSES } from "./license.types";

const INITIAL_STATE = {
  licenses: [],
  enterpriseLicenses: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LICENSES:
      return {
        ...state,
        licenses: [...action.payload.licenses]
      };
    case SET_ENTERPRISE_LICENSES:
    return {
        ...state,
        enterpriseLicenses: {...action.payload.licenses}
    };

    default:
      return state;
  }
};

export default reducer;
