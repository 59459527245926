import React  from 'react'
import Logo from '../assets/vodium-logo.png'
import Form from '../shared/Form'
import { AuthConfig } from '../configs/auth'
import { ActionTypes, STORAGE } from '../shared/common/constant'
import { logInWithEmailAndPassword, sendPasswordReset } from '../services/auth.service'
import { connect } from "react-redux"
import { setAuthOnSignIn } from '../redux/auth/auth-action'
import { Modal } from '../shared/Modal'
import { useNavigate } from 'react-router-dom'
import { toggleBtnLoadingOff, toggleBtnLoadingOn } from '../services/app.service'

const Login = ({ signIn }) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const actionHandler = ({ type, data, form, event }) => {
    switch(type) {
      case ActionTypes.formSubmit:
        toggleBtnLoadingOn();
        if(form.id === AuthConfig.Form.id) {
          logInWithEmailAndPassword(data.email, data.password).then(response => {
            if(response) {
              response.date = new Date();
              response.remember_me = data.remember_me;
              window.localStorage.setItem(STORAGE.VODIUM_AUTH, JSON.stringify(response));
              signIn({ user: response });
              navigate('/dashboard');
            }
            toggleBtnLoadingOff();
          });
        } else if(form.id === AuthConfig.ForgetPasswordForm.id) {
          sendPasswordReset(data.email).then(response => {
            if(response) {
              closeModal();
            }
            toggleBtnLoadingOff();
          });
        }
        break;
      case ActionTypes.modalClose:
        closeModal();
        break;
      case ActionTypes.forgotPassword:
        openModal();
        break;
      default:
        return;
    }
  }

  return (
    <section className="py-12 sm:py-16 lg:py-28">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center">
            <img className="w-auto h-10 mx-auto" src={Logo} alt="VODIUM Back Office"/>
            <h1 className="mt-6 text-3xl font-bold text-gray-900">Back Office</h1>
          </div>

          <Form className={'my-10'} form={AuthConfig.Form} onAction={actionHandler} />

          <div className="mt-6 text-center">
            <p className="text-sm font-medium text-gray-900">VODIUM, LLC © 2021 - {(new Date().getFullYear())}. All
              rights reserved.</p>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onAction={actionHandler}
        >
          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-sm">
              <div className="">
                <div className="flex items-center justify-between">
                  <p className="text-xl font-bold text-gray-900">{AuthConfig.ForgetPasswordForm.title}</p>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="inline-flex rounded -m1.5 p-1.5 text-gray-600 hover:bg-gray-50 hover:text-gray-900 transition-all duration-200"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
                <Form className={'my-6'} form={AuthConfig.ForgetPasswordForm} onAction={actionHandler} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  )
}

function mapDispatchToProps(dispatch) {
  return { 
    signIn: (user) => dispatch(setAuthOnSignIn(user))
  }
}

export default connect(null ,mapDispatchToProps)(Login)