import { ActionTypes, FORM } from "../shared/common/constant";

export const DateConfig = {
  GenerateDate: {
    id: "modifyDateTime",
    title: "Update license key expiration date",
    fields: [
      {
        element: FORM.ELEMENTS.DATE_PICKER,
        label: "Expiration date",
        name: "expirationDateTime",
        type: FORM.TYPES.DATE,
        className: "",
        required: true,
        options: {
          minDate: new Date(),
        },
      },
    ],
    buttons: [
      {
        type: FORM.TYPES.BUTTON,
        label: "Cancel",
        onActionType: ActionTypes.modalClose,
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900",
      },
      {
        type: FORM.TYPES.SUBMIT,
        label: "Update",
        onActionType: "",
        className:
          "inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500",
      },
    ],
  },
};
