import { ADD_ALERT, HIDE_BTN_LOADING, HIDE_LOADING, REMOVE_ALERT, SHOW_BTN_LOADING, SHOW_LOADING } from "./app.types";

export const addAlert = ({ type, text }) => {
  return {
    type: ADD_ALERT,
    payload: {type, text},
  };
};

export const removeAlert = ({ index }) => {
  return {
    type: REMOVE_ALERT,
    payload: { index }
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
    payload: null
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
    payload: null
  };
};

export const showBtnLoading = () => {
  return {
    type: SHOW_BTN_LOADING,
    payload: null
  };
};

export const hideBtnLoading = () => {
  return {
    type: HIDE_BTN_LOADING,
    payload: null
  };
};
