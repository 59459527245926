import { API_ORIGIN, HTTP_METHODS } from "../shared/common/constant";
import HttpService from "./http.service";

class DetailService {
  static getLicenseDetails = async (key) => {
    try {
      const endpoint = API_ORIGIN + `licenses/license-detail?key=${key}`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to get license details: " + error);
    }
  };

  static getActivityDetails = async (key) => {
    try {
      const endpoint = API_ORIGIN + `licenses/activity-detail?key=${key}`;

      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw Error("Failed to get license activity details: " + error);
    }
  };
}

export default DetailService;
