import { Pattern, FORM } from "./common/constant";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function validateFieldByType({ type, value, element }) {
  const errors = [];
  switch (type) {
    case FORM.TYPES.EMAIL:
      if (!Pattern.emailRegex.test(value)) {
        errors.push("Email address is invalid.");
      }
      break;
    case FORM.TYPES.PASSWORD:
      if (!value || value.length < 8) {
        errors.push("Minimum password length of 8 characters.");
      }
      break;
    case FORM.TYPES.TEXT:
      if (element === FORM.ELEMENTS.DATE_PICKER) {
        if (!value.length) {
          errors.push("Invalid date.");
        }
      }
      if (element === FORM.ELEMENTS.INPUT_CODE) {
        if (Pattern.specialCharacterRegex.test(value)) {
          errors.push("Invalid access code. Access code must not contain special characters.");
        }
      } else {
        if (!Pattern.nameRegex.test(value)) {
          errors.push("Invalid text.");
        }
      }
      break;
    case FORM.TYPES.URL:
      if (
        value.length <= 1 ||
        value[0] === "." ||
        value[value.length - 1] === "." ||
        value.indexOf(".") === -1 ||
        !Pattern.domainRegex.test(value) ||
        Pattern.specialCharacterRegex.test(value)
      ) {
        errors.push(
          'This must be an email domain only, excluding the @ symbol. For example ,"vodium.com".'
        );
      }
      break;
    case FORM.TYPES.NUMBER:
      if (!Pattern.numericRegex.test(value)) {
        errors.push("Invalid number");
      }
      break;
    case FORM.TYPES.CHECKBOX:
      if (!value || value === false || value === "false") {
        errors.push("This field is required");
      }
      break;
    default:
      if (!value.length || value === "0") {
        errors.push("Field should not be empty.");
      }
      break;
  }
  return errors;
}

export const DateFormat = (str, hours) => {
  if (hours) {
    const timeHours = new Date(str).getHours();
    const timeMinutes = new Date(str).getMinutes();
    return `${new Date(str).toISOString().split("T")[0]} \xa0 ${
      timeHours <= 9 ? "0" + timeHours : timeHours
    }:${timeMinutes <= 9 ? "0" + timeMinutes : timeMinutes}`;
  } else {
    return `${new Date(str).toISOString().split("T")[0]}`;
  }
};
