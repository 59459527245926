export const PaidConfig = {
	tableMenuOptions: [
		{
			id: 1,
			value: 'Stripe customer'
		},
		{
			id: 2,
			value: 'Subscription details'
		},
		{
			id: 5,
			value: 'License Activity details'
		}
	]
}