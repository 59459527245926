import { ActionTypes, FORM } from '../shared/common/constant';

export const AuthConfig = {
    Form: {
		id: 'loginForm',
		title: 'Create new customers',
		fields: [
			{
				element: FORM.ELEMENTS.INPUT,
				label: 'Email',
				name: 'email',
				type: FORM.TYPES.EMAIL,
				required: true,
				defaultValue: '',
				placeholder: 'Email address',
				className: ''
			},
			{
				element: FORM.ELEMENTS.INPUT,
				label: 'Password',
				name: 'password',
				type: FORM.TYPES.PASSWORD,
				required: true,
				defaultValue: '',
				placeholder: 'Password',
				className: '',
				isInlineLabel: true,
				anchor: 'Forgot Password?',
				anchorLink: '/',
				onActionType: ActionTypes.forgotPassword,
			},
			{
				element: FORM.ELEMENTS.INPUT_CHECKBOX,
				label: 'Remember Me',
				name: 'remember_me',
				type: FORM.TYPES.CHECKBOX,
				required: false,
				defaultValue: '',
				placeholder: '1',
				className: ''
			}
		],
		buttons: [
			{
				type: FORM.TYPES.SUBMIT,
				label: 'Sign in',
				onActionType: '',
				className: 'inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500'
			}
		]
	},
	ForgetPasswordForm: {
		id: 'forgetPasswordForm',
		title: 'Forget password',
		fields: [
			{
				element: FORM.ELEMENTS.INPUT,
				label: 'Email',
				name: 'email',
				type: FORM.TYPES.EMAIL,
				required: true,
				defaultValue: '',
				placeholder: 'Email address',
				className: ''
			},
		],
		buttons: [
			{
				type: FORM.TYPES.SUBMIT,
				label: 'Submit',
				onActionType: '',
				className: 'inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500'
			}
		]
	}
}