import React from 'react'
import { connect } from "react-redux"
import ActionHandler from '../services/data-handler'
import { ACTION_HANDLER_TYPE } from '../shared/common/constant'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const date = new Date()
let monthName = months[date.getMonth()]

const Dashboard = ({ user }) => {
  const [userDetails, setUserDetails] = React.useState();
  const [data, setData] = React.useState();

  React.useEffect(() => {
    ActionHandler(ACTION_HANDLER_TYPE.GET_METRICS).then(metrics => {
      setData(metrics);
    })
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    setUserDetails(user);
    // eslint-disable-next-line
  }, [user])

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="py-6 mt-6">
          <div className="px-6 mx-auto">
            <div className="md:items-center md:flex">
              <p className="text-base font-bold text-gray-900">Hey {userDetails ? userDetails.name : ''} -</p>
              <p className="mt-1 text-base font-regular text-gray-500 md:mt-0 md:ml-2">here are your {monthName} stats
                for VODIUM</p>
            </div>
          </div>
        </div>

        {
          data !== undefined &&
          <div className="px-6 mx-auto">
            <div className="space-y-5 sm:space-y-6">
              <div className="grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                <div className="bg-white border border-gray-200 rounded">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Active licenses</p>
                    <div className="flex items-center justify-between mt-3">
                      <p className="text-xl font-bold text-gray-900">{data.activateLicenses}</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Paid users</p>
                    <div className="flex items-center justify-between mt-3">
                      <p className="text-xl font-bold text-gray-900">{data.paidUsers}</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Trial requests</p>
                    <div className="flex items-center justify-between mt-3">
                      <p className="text-xl font-bold text-gray-900">{data.trialRequests}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

      </main>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(Dashboard)