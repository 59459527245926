import { ActionTypes, FORM, UserRole } from '../shared/common/constant';

export const SettingsConfig = {
	Form: {
		id: 'addUserForm',
		title: 'Add new users',
		fields: [
			{
				element: FORM.ELEMENTS.INPUT,
				label: 'Name',
				name: 'name',
				type: FORM.TYPES.TEXT,
				required: true,
				defaultValue: '',
				placeholder: 'John Doe',
				className: ''
			},
			{
				element: FORM.ELEMENTS.INPUT,
				label: 'Email',
				name: 'email',
				type: FORM.TYPES.EMAIL,
				required: true,
				defaultValue: '',
				placeholder: 'Email address',
				className: ''
			},
			{
				element: FORM.ELEMENTS.INPUT,
				label: 'Password',
				name: 'password',
				type: FORM.TYPES.PASSWORD,
				required: true,
				defaultValue: '',
				placeholder: 'Password (min. 8 characters)',
				className: '',
				isInlineLabel: false
			},
			{
				element: FORM.ELEMENTS.SELECT,
				label: 'User role',
				name: 'user_role',
				required: true,
				defaultValue: 2,
				placeholder: 'Select user role',
				className: '',
				options: UserRole
			},
		],
		buttons: [
			{
				type: FORM.TYPES.BUTTON,
				label: 'Cancel',
				onActionType: ActionTypes.modalClose,
				className: 'inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900'
			},
			{
				type: FORM.TYPES.SUBMIT,
				label: 'Add',
				onActionType: '',
				className: 'inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500'
			}
		]
	}
}