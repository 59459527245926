import React from 'react'
import Table from '../../components/Table'
import ActionHandler from '../../services/data-handler'
import { ACTION_HANDLER_TYPE } from '../../shared/common/constant'
import { DateFormat } from '../../shared/Utils'

const Trial = () => {
  const [data, setData] = React.useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: row => DateFormat(row.createdAt, true),
      },
      {
        Header: 'Name',
        accessor: row => row.firstName + ' ' + row.lastName,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Company',
        accessor: row => row.company ?? ' ',
      },
      {
        Header: 'Job Title',
        accessor: row => row.jobTitle ?? ' ',
      }
    ],
    []
  )

  React.useEffect(() => {
    getTrials();
    // eslint-disable-next-line
  }, []);

  const getTrials =  async () => {
    const trials = await ActionHandler(ACTION_HANDLER_TYPE.GET_TRIALS);

    trials.trials.sort((a,b)=>{
      return new Date(b.createdAt) - new Date(a.createdAt);
    })

    setData(trials.trials);
  }

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden">
      <main>
        <div className="py-11">
          <div className="px-6 mx-auto">
            <Table title="Trial Users" columns={columns} data={data}/>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Trial