import { setAuthOnLogout, setAuthToken } from "../redux/auth/auth-action";
import { showBtnLoading, hideBtnLoading } from "../redux/app/app-action";
import store from "../redux/store";
import { sidebarResponsiveWidth, STORAGE } from "../shared/common/constant";
import { currentUserState, logout } from "./auth.service";

const appInit = async () => {
  return new Promise((resolve, reject) => {
    currentUserState(async (user) => {
      resolve(true);
      if(user) {
        store.dispatch(setAuthToken({ token: user.accessToken }))
        handleSessionExpiry();
      }
    })
  })
};

const handleSessionExpiry = () => {
  const authStorage = window.localStorage.getItem(STORAGE.VODIUM_AUTH);
  if (authStorage) {
    const auth = JSON.parse(authStorage);
    const storedDate = new Date(auth.date);
    const today = new Date();
    const diffTime = Math.abs(storedDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (auth.remember_me) {
      if (diffDays >= 28) {
        window.localStorage.removeItem(STORAGE.VODIUM_AUTH);
        appLogout();
      }
    } else {
      if (diffDays > 1) {
        window.localStorage.removeItem(STORAGE.VODIUM_AUTH);
        appLogout();
      }
    }
  }
}

const appLogout = () => {
  logout();
  store.dispatch(setAuthOnLogout());
  window.localStorage.removeItem(STORAGE.VODIUM_AUTH);
}

const toggleSidebar = () => {
  const sidebar = document.getElementById("sidebar");
  if (sidebar.offsetWidth % sidebarResponsiveWidth === 0) {
    if (sidebar.classList.contains("-left-60")) {
      sidebar.classList.add("left-0");
      sidebar.classList.remove("-left-60");
    } else {
      sidebar.classList.add("-left-60");
      sidebar.classList.remove("left-0");
    }
  }
};

const isAdmin = (user) => {
  if (+user.role === 1) {
    return true;
  }
  return false;
};

const toggleBtnLoadingOn = () => {
  store.dispatch(showBtnLoading())
}


const toggleBtnLoadingOff = () => {
  store.dispatch(hideBtnLoading())
}


export { 
  appInit,
  appLogout,
  toggleSidebar,
  isAdmin,
  toggleBtnLoadingOn,
  toggleBtnLoadingOff
};